export default [
    {
        path: '/',
        name: 'home',
        component: () => import('@/pages_mobile/Home/Home.vue'),
        meta: {
            auth: false,//需要登录效验
        },
        // redirect: 'index',//路由重定向
        // children: [
        //     {
        //         path: '/index',
        //         name: 'index-page',
        //         component: () => import('@/pages_mobile/IndexPage/IndexPage.vue'),
        //         meta: {
        //             auth: true,//需要登录效验
        //         },
        //     },
        // ]
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/pages_mobile/Login/Login.vue'),
        meta: {
            auth: false,//不需要登录效验
        }
    }
];