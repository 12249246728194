import Vue from 'vue'
import VueRouter from 'vue-router'
import comm from '@/utils/comm';

import routerPcList from './router_pc';
import routerMobileList from './router_mobile';

import storage from '@/utils/storage'

Vue.use(VueRouter)

const isUseMobile = true;//是否启用移动端

let routes = [];
/**
 * 根据PC还是Mobile同路由加载不同组件
 * 
 * 业务需求：路由地址不变，根据浏览器环境变换相应的页面展示，由于PC和Mobile页面展示形式不同所以加载不同页面或组件
 * 
 * 配置要求：同一路由地址必须在两端都进行配置
 */
if (isUseMobile) {
  if (!comm.isMobile()) {
    //PC端
    routes = routerPcList;
  } else {
    //移动端
    routes = routerMobileList;
  }
} else {
  routes = routerPcList;
}

//防止路由跳转相同报错
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

//创建router
const router = new VueRouter({
  // mode: 'hash',
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

/**
 * 路由守卫
 */
router.beforeEach((to, from, next) => {
  var userInfoStr = window.localStorage.getItem(comm.token_key);
  var userInfo = null;
  if (userInfoStr) {
    userInfo = JSON.parse(userInfoStr);
  }

  if (to.path == '/login' && userInfo && userInfo.token) {
    //登录页面如果已经登录过去首页
    next({
      path: '/',
    });
  } else if (to.meta && to.meta.auth && (!userInfo || !userInfo.token)) {
    //需要验证登录的页面
    next({
      path: '/login',
    });
  } else {
    if (window._hmt) {
      if (to.path) {
        window._hmt.push(['_trackPageview', '/#' + to.fullPath])
      }
    }
    next();
  }
})

export default router