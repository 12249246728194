export default [
    {
        path: '/',
        name: 'home',
        component: () => import('@/pages/Home/Home.vue'),
        meta: {
            auth: false,//需要登录效验
        },
        children: [
            {
                path: '/index.html',
                name: 'index-page',
                component: () => import('@/pages/Home/Home.vue'),
                meta: {
                    auth: false,//需要登录效验
                },
            },
        ]
    },
];
