let u = {
    token_key: 'fenchuan_user_info',//缓存token的key  is_token
    isMobile: () => {//是否是移动端
        const ua = navigator.userAgent.toLowerCase();
        const t1 = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
            ua
        );
        const t2 = !ua.match("iphone") && navigator.maxTouchPoints > 1;
        return t1 || t2;
    }
};


export default u;