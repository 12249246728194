import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

//Element-ui（PC端用的框架）
import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/styles/element-variables.scss';
import '@/assets/styles/index.scss';
Vue.use(ElementUI);

//Vant（移动端用的UI框架）
import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);

//移动端使用rem
// import 'lib-flexible/flexible';


//axios
import axios from 'axios';
Vue.prototype.axios = axios;

//公用
import comm from './utils/comm';
Vue.prototype.$token_key = comm.token_key;//缓存token的key

import {preventReClick} from './utils/plugins.js'
Vue.directive('preventReClick', preventReClick)

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
