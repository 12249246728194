import Storage from 'good-storage'

const storage = {
  session: {}
}

/**
 * @description 存储 localStorage 值
 * @param {String} name localStorage name
 * @param {String} value localStorage value
 */
storage.set = function (name = 'default', value = '') {
  if(value){
    Storage.set(`${name}`, value)
  }else{
    Storage.remove(`${name}`)
  }
}

/**
 * @description 拿到 localStorage 值
 * @param {String} name localStorage name
 */
storage.get = function (name = 'default') {
  return Storage.get(`${name}`)
}

/**
 * @description 拿到 Storage 全部的值
 */
storage.getAll = function () {
  return Storage.getAll()
}

/**
 * @description 删除 localStorage
 * @param {String} name localStorage name
 */
storage.remove = function (name = 'default') {
  return Storage.remove(`${name}`)
}
/**
 * @description 删除全部Storage
 */
storage.clear = function () {
  return Storage.clear()
}
/**
 * @description 判断是否有 localStorage
 * @param {String} name localStorage name
 */
storage.has = function (name = 'default') {
  return Storage.has(`${name}`)
}

/**
 * @description 存储 sessionStorage 值
 * @param {String} name sessionStorage name
 * @param {String} value sessionStorage value
 */
storage.session.set = function (name = 'default', value = '') {
  Storage.session.set(`${name}`, value)
}

/**
 * @description 拿到 sessionStorage 值
 * @param {String} name sessionStorage name
 */
storage.session.get = function (name = 'default') {
  return Storage.session.get(`${name}`)
}

/**
 * @description 拿到 Storage 全部的值
 */
storage.session.getAll = function () {
  return Storage.session.getAll()
}
/**
 * @description 删除 sessionStorage
 * @param {String} name sessionStorage name
 */
storage.session.remove = function (name = 'default') {
  return Storage.session.remove(`${name}`)
}
/**
 * @description 删除全部Storage
 */
storage.session.clear = function () {
  return Storage.session.clear()
}
/**
 * @description 判断是否有 sessionStorage
 * @param {String} name sessionStorage name
 */
storage.session.has = function (name = 'default') {
  return Storage.session.has(`${name}`)
}

export default storage

