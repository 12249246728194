<template>
  <div id="app">
    <!--内容-->
    <transition>
      <router-view />
    </transition>
  </div>
</template>
<script>

export default {
  name: 'App',
  data() {
    return {
    }
  },
  mounted() {

  }
}
</script>

<style lang="scss">
html,
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  color: #333333;
  margin: 0;
  width: 100%;
  height: 100%;
  background-color: #F6F7F9;
  letter-spacing: 1px;
}

input,
textarea {
  outline: none;
}

input {
  outline: none;
}

img {
  width: 100%;
  height: 100%;
}

#app {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.pageView {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  color: #333;
  box-sizing: border-box;
}

.van-button--primary {
  color: #fff !important;
  background-color: #FDDD11 !important;
  border: 1px solid #FDDD11 !important;
}
</style>
